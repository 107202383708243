
import { mapGetters } from "vuex";
import BlocksContainer from "~/components/blocks/BlocksContainer";
import { metaGenerator, getImageByPosition } from "@/utils";
import googleAnalyticsEvents from "~/mixins/googleAnalyticsEvents.js";
import builderPageMixin from "~/builder/builderPageMixin";
import jsonLdMixin from "~/mixins/jsonLdMixin";

export default {
  name: "Index",
  components: {
    BlocksContainer,
  },
  mixins: [googleAnalyticsEvents, builderPageMixin, jsonLdMixin],
  async asyncData({ $api, error, store }) {
    const getPage = async () => {
      try {
        const { data } = await $api.pages.getPage("home");
        return data.data;
      } catch (err) {
        console.log("err - ", err?.response?.data?.errors);
        error({ statusCode: err?.response?.status });
      }
    };
    const res = await getPage();
    const blocks = res?.blocks;
    const translation = res?.mixedTranslation;
    const medias = res?.medias;

    store.commit("general/SET_JSON_SCHEMA_DATA", {
      pageName: translation?.title,
      pageImage:
        getImageByPosition(medias, "open-graph")?.file?.url ||
        getImageByPosition(medias, "page")?.file?.url,
    });
    return {
      pageData: res,
      components: blocks || [],
      pageContent: { ...translation, medias },
    };
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({
      addSeoAttributes: true,
    });
    const jsonLdPayload = this.setHomePageJsonLdSchema();
    return {
      meta: [
        ...metaGenerator({
          title: this.pageContent?.meta_title || this.pageContent?.title || "",
          description: this.pageContent?.meta_description || "",
          image:
            this.pageContent?.medias?.find((el) => el.position === "open-graph")
              ?.file?.url || "",
          keywords:
            this.pageContent?.meta_keywords || this.pageContent?.title || "",
          url:
            this.pageContent?.meta_url ||
            process.env.DOMAIN + this.$route.fullPath,
        }),
        ...i18nHead.meta,
      ],
      htmlAttrs: i18nHead.htmlAttrs,
      link: i18nHead.link,
      title: this.pageContent?.meta_title || this.pageContent?.title || "",
      titleTemplate: `%s | ${this.getCompanyName}`,
      script: [
        {
          hid: this.getJsonLdScriptHid(jsonLdPayload),
          type: "application/ld+json",
          json: jsonLdPayload,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCompanyName: "general/getCompanyName",
    }),
    canSendGoogleAnalyticsStatistic() {
      return this.integrationCallbackStatuses.TYPE_GOOGLE_ANALYTICS;
    },
  },
  watch: {
    canSendGoogleAnalyticsStatistic: {
      handler() {
        this.gaDynamicParamsEvent("View Home", {
          ecomm_prodid: "",
          ecomm_pagetype: "home",
          ecomm_totalvalue: "",
          ecomm_category: "",
        });
      },
      immediate: true,
    },
  },
};
